import { gsap } from 'gsap'
import Head from 'next/head'
import React from 'react'

import ListingsList from '../components/ListingsList'
import RenderPageBuilder from '../components/RenderPageBuilder'
import { default as sanity, listingsSanityClient } from '../sanity'
import { sanityListingsQuery } from '../sanity/listing'
import { sanityPageQuery } from '../sanity/page'
import { getBrandName, substoreSettings } from '../utils/substores.config'

interface IOffers {
  sanityListings: any[] // TODO:
  page: any
}

const LandingPage = ({ sanityListings, page }: IOffers) => {
  const [resized, setResized] = React.useState(false)
  const hero = page.pageBuilder.filter(el => el._type === 'hero')
  const rest = page.pageBuilder.filter(el => el._type !== 'hero')

  const comp = React.useRef()

  React.useLayoutEffect(() => {
    let resizeObserver: ResizeObserver = { observe: () => {}, disconnect: () => {}, unobserve: () => {} }

    let ctx = gsap.context(() => {
      // TODO: fix resizing
      // if (window.ResizeObserver) {
      //   resizeObserver = new ResizeObserver(
      //     debounce(() => {
      //       if (resized) {
      //         wrappers.split({ types: 'lines' })
      //         titleWrappers.split({ types: 'lines' })
      //         subtitleWrappers.split({ types: 'lines' })
      //         // SplitText.create(wrappers.lines, { types: 'lines', lineClass: linesClass })
      //         // lines.split({ types: 'lines' })
      //       } else {
      //         setResized(true)
      //       }
      //     }, 500)
      //   )

      //   resizeObserver.observe(comp.current)
      // }

      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.js-scrollTrigger',
          start: '40% bottom',
        },
      })

      tl2.from('div.section1-bg', {
        x: '-50%',
        opacity: 0,
        duration: 0.7,
      })

      tl2.from(
        '.section1-usp',
        {
          stagger: 0.1,
          x: -10,
          opacity: 0,
          ease: 'power4.in',
          duration: 0.5,
        },
        '=-0.8'
      )
    }, comp)

    return () => {
      ctx.revert()
      ctx.kill()
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div ref={comp}>
      <Head>
        <title>{getBrandName('Justdrive')} - bil i 120 dage</title>
        <meta
          name="description"
          content="JUSTDRIVE er bil på abonnement. De sure pligter som forsikring, vægtafgift og dyre værkstedsregninger er taget ud af ligningen."
        />
        <link rel="canonical" href="https://justdrive.today/" />
        <meta property="og:locale" content="da_DK" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Justdrive - bil i 120 dage" />
        <meta
          property="og:description"
          content="JUSTDRIVE er din bil i 120 dage, til dig som gerne vil have bil på abonnement, minilease eller korttids leasing. Uden udbetaling og depositum"
        />
        <meta property="og:url" content="https://justdrive.today/" />
        <meta property="og:site_name" content="JUSTDRIVE" />
        <meta property="article:modified_time" content="2023-02-11T21:06:20+00:00" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Estimeret læsetid" />
        <meta name="twitter:data1" content="5 minutter" />
      </Head>

      <RenderPageBuilder blocks={hero} />

      <section className="landing-section1 relative flex items-center md:h-screen overflow-hidden mt-20 py-20 md:py-0">
        <div className="bg-gradient-to-r from-white to-green-500 clip-arrow js-scrollTrigger section1-bg absolute h-full max-w-none z-0 w-[140%] -right-[40%] sm:w-[120%] sm:-right-[20%]"></div>
        {/* <img
          src={`mark-green-gradient-bg-solid2.png`}
          className="js-scrollTrigger section1-bg absolute h-full max-w-none w-auto z-0 -right-[20%]"
        /> */}

        <div className="relative w-full md:grid grid-cols-3 gap-8 px-4 md:px-8 mx-auto z-10">
          <div className="section1-usp col-span-1">
            <p className="">01</p>
            <h2 className=" text-3xl md:text-4xl mt-2">Lynhurtig levering</h2>
            <p className="mt-6 md:mt-16 w-4/5">
              Ingen gider ventetid. Vi har automatiseret alt, så vi kan fokusere på at finde de bedste biler og levere dem
              lynhurtigt - oftest to dage efter, du har bestilt.
            </p>
          </div>

          <div className="section1-usp col-span-1 my-20 md:my-0">
            <p className="">02</p>
            <h2 className=" text-3xl md:text-4xl mt-2">Fuld fleksibilitet</h2>
            <p className="mt-6 md:mt-16 w-4/5">
              Ingen lange bindingsperioder. Du har din nye bil i 120 dage og derefter kan du selv vælge om du vil fortsætte -
              eventuelt med en ny model.
            </p>
          </div>

          <div className="section1-usp col-span-1">
            <p className="">03</p>
            <h2 className=" text-3xl md:text-4xl mt-2">Attraktive priser</h2>
            <p className="mt-6 md:mt-16 w-4/5">
              Ingen skjulte gebyrer eller kompromisser. Gennem vores netværk sikrer vi den bedste pris, så du kan koncentrere dig
              om, hvilken bil du vil have.
            </p>
          </div>
        </div>
      </section>

      <ListingsList sanityListings={sanityListings} />

      <RenderPageBuilder blocks={rest} />
    </div>
  )
}

export async function getStaticProps() {
  const sanityListings = await listingsSanityClient.fetch<any[]>(sanityListingsQuery)
  const page = await sanity.fetch<any>(sanityPageQuery('landing-page'))

  return {
    props: {
      sanityListings,
      page,
    },
  }
}

export default LandingPage
